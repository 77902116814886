import { Divider } from "@/components/Divider/Divider.component";
import clsx from "clsx";
import type { ReactNode } from "react";
type CardHeadingProps = {
  readonly className?: string;
  readonly children: ReactNode;
};
export function CardHeading({
  children,
  className
}: CardHeadingProps) {
  return <header className="grid gap-2" data-sentry-component="CardHeading" data-sentry-source-file="CardHeading.component.tsx">
      <h3 className={clsx(className, "hyphens-manual break-words text-center text-xl font-bold leading-tight")}>
        {children}
      </h3>
      <Divider className="relative" orientation="horizontal" data-sentry-element="Divider" data-sentry-source-file="CardHeading.component.tsx" />
    </header>;
}