import { RegularLinkButton } from "@/components/Button/RegularLinkButton/RegularLinkButton.component";
import { Card } from "@/components/Card/Card.component";
import { CardHeading } from "@/components/Card/_components/CardHeading/CardHeading.component";
import { List } from "@/components/List/List.component";
import { ListItem } from "@/components/List/ListItem.component";
import { Translation } from "@/components/Translation/Translation.component";
import type { Fetchable } from "@/services/utilities/fetchable";
import { initial, isPending } from "@/services/utilities/fetchable";
import type { PaywallModel } from "../../models/paywall.model";
type CorporateCardProps = {
  readonly apps: null | PaywallModel.Apps;
  readonly url: string;
  readonly variant?: "outline" | "primary";
  readonly state?: Fetchable;
  readonly position?: string;
};
export function CorporateCard({
  state = initial(),
  url,
  variant = "primary",
  position
}: CorporateCardProps) {
  return <Card elevated={true} data-sentry-element="Card" data-sentry-component="CorporateCard" data-sentry-source-file="CorporateCard.components.tsx">
      <CardHeading data-sentry-element="CardHeading" data-sentry-source-file="CorporateCard.components.tsx">
        <Translation da="Virksomheds&shy;abonnement" de="Team- und unternehmens&shy;weite Lizenz" en="Corporate subscription" no="Bedrifts&shy;abonnement" sv="Företags&shy;abonnemang" data-sentry-element="Translation" data-sentry-source-file="CorporateCard.components.tsx" />
      </CardHeading>

      <p className="text-left">
        <Translation da="Med et virksomhedsabonnement får I:" de="Mit einem Unternehmensabonnement erhalten Sie:" en="With a corporate subscription you get:" no="Med et bedriftsabonnement får dere:" sv="Med ett företagsabonnemang får ni:" data-sentry-element="Translation" data-sentry-source-file="CorporateCard.components.tsx" />
      </p>

      <List className="gap-1 text-left" type="checkmark" data-sentry-element="List" data-sentry-source-file="CorporateCard.components.tsx">
        <ListItem data-sentry-element="ListItem" data-sentry-source-file="CorporateCard.components.tsx">
          <Translation da="Alle fordele fra et personligt abonnement" de="Vorteile Ihrer persönlichen Lizenz" en="All the benefits of a personal subscription" no="Alle fordelene fra våre personlige abonnementer" sv="Alla fördelar med en personlig prenumeration" data-sentry-element="Translation" data-sentry-source-file="CorporateCard.components.tsx" />
        </ListItem>

        <ListItem data-sentry-element="ListItem" data-sentry-source-file="CorporateCard.components.tsx">
          <Translation da="Løsning tilpasset din virksomheds behov" de="Lösung, die auf die Bedürfnisse Ihres Unternehmens zugeschnitten ist" en="Solution tailored to your company's needs" no="Løsning tilpasset din bedrifts behov" sv="Lösning anpassad till ditt företags behov" data-sentry-element="Translation" data-sentry-source-file="CorporateCard.components.tsx" />
        </ListItem>

        <ListItem data-sentry-element="ListItem" data-sentry-source-file="CorporateCard.components.tsx">
          <Translation da="Attraktiv pris per bruger" de="Attraktiver Preis pro Benutzer" en="Attractive price per user" no="Attraktiv pris per bruker" sv="Attraktivt pris per användare" data-sentry-element="Translation" data-sentry-source-file="CorporateCard.components.tsx" />
        </ListItem>
      </List>

      <RegularLinkButton disabled={isPending(state)} href={url} position={position} variant={variant} data-sentry-element="RegularLinkButton" data-sentry-source-file="CorporateCard.components.tsx">
        <Translation da="Bestil adgang" de="Zugang bestellen" en="Order access" no="Bestill tilgang" sv="Beställ åtkomst" data-sentry-element="Translation" data-sentry-source-file="CorporateCard.components.tsx" />
      </RegularLinkButton>
    </Card>;
}