export const conversionEvents = {
  addTrialArticleEvent: ["add_trial", "article"],
  createTrialArticleEvent: ["create_trial", "article"],
  newsletterArticleEvent: ["newsletter", "article"],
  newsletterFrontpageEvent: ["newsletter", "frontpage"],
} as const;

export type ConversionEvent =
  (typeof conversionEvents)[keyof typeof conversionEvents];

/**
 * More information and properties can be found in the Kilkaya documentation:
 * https://docs.google.com/spreadsheets/d/1AtAjcZt9pznS0bEHGtu2RpK8Q5IzCY6A_0rZMtExLnA/edit?gid=0#gid=0
 */
export type KilkayaClickEvent = {
  /**
   * The position is an abreviation that Kilkaya uses to as an identifier for where the click event happened.
   *
   * Please note that Kilkaya has a limitation of 32 characters.
   *
   * A list of the allowed positions can be found here:
   *
   * [https://jira-jppol.atlassian.net/wiki/spaces/Watch/pages/3224141845/Kilkaya+Position+Abbreviations](https://jira-jppol.atlassian.net/wiki/spaces/Watch/pages/3224141845/Kilkaya+Position+Abbreviations)
   */
  readonly position: string;

  /**
   * The URL where the click event should navigate to.
   */
  readonly toUrl: string;

  /**
   * The current URL of the page where the click event happened.
   */
  readonly url: string;
};
