"use client";

import type { ShopLinks } from "@/app/_api/shop/_models/shop-links.model";
import { ConsumerCard } from "@/components/Paywall/_components/ConsumerCard/ConsumerCard.components";
import { CorporateCard } from "@/components/Paywall/_components/CorporateCard/CorporateCard.components";
import { conversionEvents } from "@/components/Tracking/Kilkaya/kilkaya.types";
import { useSiteConfiguration } from "@/contexts/site-configuration/site-configuration.context";
import { useSite } from "@/contexts/site/site.context";
import { useUser } from "@/contexts/user/hooks/use-user.hook";
import { useKilkayaClickEvent } from "@/hooks/tracking/kilkaya/use-kilkaya-tracking.hook";
import { useTracking } from "@/hooks/tracking/use-tracking";
import { useTrackingVersion } from "@/hooks/tracking/use-tracking-version";
import type { Fetchable } from "@/services/utilities/fetchable";
import { failed, initial, pending } from "@/services/utilities/fetchable";
import { usePathname } from "next/navigation";
import { useState } from "react";
import { AddTrialLink } from "../AddTrialLink/AddTrialLink.component";
import { SwitchFetchable } from "../SwitchFetchable/SwitchFetchable.component";
import { Translation } from "../Translation/Translation.component";
import { PaywallLayout } from "./_components/PaywallLayout/PaywallLayout.component";
import { PaywallTitle } from "./_components/PaywallTitle/PaywallTitle.component";
import { SuccessBox } from "./_components/SuccessBox/SuccessBox.component";
import { useAddTrial } from "./_hooks/use-add-trial.hook";
import type { PaywallModel } from "./models/paywall.model";
type PaywallProps = {
  readonly className?: string;
  readonly model: PaywallModel;
  readonly initialSubmissionState?: Fetchable;
  readonly shopLinks: ShopLinks;
};
export const trialLinkTranslations = {
  da: "Få et uforpligtende prøveabonnement her.",
  de: "Erhalten Sie hier ein unverbindliches Probeabonnement.",
  en: "Get a non-binding trial subscription here.",
  no: "Få et uforpliktende prøveabonnement her.",
  sv: "Få ett icke-bindande provabonnemang här."
};
export function AuthenticatedPaywall({
  className,
  initialSubmissionState = initial(),
  model,
  shopLinks: {
    corporateUrl,
    consumerUrl
  }
}: PaywallProps) {
  const pathname = usePathname();
  const {
    features: {
      loginProvider
    }
  } = useSiteConfiguration();
  const {
    domain,
    name: siteName
  } = useSite();
  const [containerState, setContainerState] = useState(initialSubmissionState);
  const {
    addTrialToCurrentProfile
  } = useAddTrial();
  const {
    model: {
      user
    }
  } = useUser();
  const {
    trackClick: trackClickV2
  } = useTracking();
  const {
    trackClick: trackClickV1
  } = useKilkayaClickEvent();
  const trackingVersion = useTrackingVersion();
  const {
    apps,
    articleId,
    trial
  } = model;
  async function trialClick() {
    setContainerState(pending());
    if (trackingVersion === "v2") {
      trackClickV2({
        position: "pw-adtr-btn",
        toUrl: `https://${domain}${pathname}`,
        url: `https://${domain}${pathname}`
      });
    }
    if (trackingVersion === "v1") {
      trackClickV1({
        position: "pw-adtr-btn",
        toUrl: `https://${domain}${pathname}`,
        url: `https://${domain}${pathname}`
      });
    }
    if (!user) {
      return setContainerState(failed("USER_NOT_FOUND"));
    }
    const result = await addTrialToCurrentProfile(articleId);
    setContainerState(result);
  }
  return <SwitchFetchable fetchable={containerState} renderDefault={() => <PaywallLayout className={className}>
          <PaywallTitle model={{
      da: `Du er logget ind, men du er ikke tilknyttet et aktivt abonnement på ${siteName}`,
      de: `Sie sind angemeldet, aber mit keinem aktiven Abonnement für ${siteName} verbunden`,
      en: `You are logged in, but you do not have an active subscription to ${siteName}`,
      no: `Du er logget inn, men du er ikke tilknyttet et aktivt abonnement på ${siteName}`,
      sv: `Du är inlogg, men har inte ett aktivt abonnemang på ${siteName}`
    }} />

          <ConsumerCard position="pw-bbtn" url={consumerUrl} state={containerState} variant="primary" />

          <CorporateCard apps={apps} state={containerState} url={corporateUrl} variant="primary" position="pw-co-bbtn" />

          {trial !== null && !trial.isUserTrialExpired ? <AddTrialLink addTrialComponent={<button className="text-primary hover:underline" data-k5a-pos="pw-adtr-btn" onClick={trialClick} type="submit">
                  <Translation model={trialLinkTranslations} />
                </button>} articleId={articleId} numberOfTrialDays={trial.numberOfFreeTrialDays} state={containerState} /> : null}
        </PaywallLayout>} renderSucceeded={() => <SuccessBox className={className} model={{
    appStore: apps?.appStoreUrl ?? null,
    conversionEventTags: conversionEvents.addTrialArticleEvent,
    googlePlayUrl: apps?.googlePlayUrl ?? null,
    loginProvider,
    state: containerState
  }} />} data-sentry-element="SwitchFetchable" data-sentry-component="AuthenticatedPaywall" data-sentry-source-file="AuthenticatedPaywall.component.tsx" />;
}