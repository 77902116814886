"use client";

import { List } from "@/components/List/List.component";
import { ListItem } from "@/components/List/ListItem.component";
import { Translation } from "@/components/Translation/Translation.component";
import { useSiteConfiguration } from "@/contexts/site-configuration/site-configuration.context";
import { useSite } from "@/contexts/site/site.context";
import { inlineJsx } from "@/ts/locale/inline-jsx";
import clsx from "clsx";
type ConsumerPerksProps = {
  readonly className?: string;
};
export function ConsumerPerks({
  className
}: ConsumerPerksProps) {
  const {
    name: siteName,
    urls: {
      appStore: {
        google: googlePlay,
        apple: appStore
      }
    }
  } = useSite();
  const {
    features: {
      newsTerm
    }
  } = useSiteConfiguration();
  return <List className={clsx(className, "grid gap-1")} type="checkmark" data-sentry-element="List" data-sentry-component="ConsumerPerks" data-sentry-source-file="ConsumerPerks.component.tsx">
      <ListItem data-sentry-element="ListItem" data-sentry-source-file="ConsumerPerks.component.tsx">
        <Translation da={inlineJsx`Premium-adgang til alt indhold på ${siteName}`} de={inlineJsx`Premium-Zugang zu allen Inhalten auf ${siteName}`} en={inlineJsx`Premium access to all content on ${siteName}`} no={inlineJsx`Premium-tilgang til alt innhold på ${siteName}`} sv={inlineJsx`Premium-åtkomst till allt innehåll på ${siteName}`} data-sentry-element="Translation" data-sentry-source-file="ConsumerPerks.component.tsx" />
      </ListItem>

      <ListItem data-sentry-element="ListItem" data-sentry-source-file="ConsumerPerks.component.tsx">
        <Translation da="Ubegrænset adgang til vores arkiv" de="Unbegrenzten Zugriff auf unser Archiv" en="Unlimited access to our archive" no="Ubegrenset tilgang til vårt arkiv" sv="Obegränsad tillgång till vårt arkiv" data-sentry-element="Translation" data-sentry-source-file="ConsumerPerks.component.tsx" />
      </ListItem>

      <ListItem data-sentry-element="ListItem" data-sentry-source-file="ConsumerPerks.component.tsx">
        {newsTerm === "field" ? <Translation da="Dagligt nyhedsbrev med de vigtigste nyheder inden for området" de="Täglicher Newsletter mit den wichtigsten Nachrichten aus dem Bereich" en="Daily newsletter with the most important news in the field" no="Daglig nyhetsbrev med de viktigste nyhetene innen området" sv="Dagligt nyhetsbrev med de viktigaste nyheterna inom området" /> : <Translation da="Daglige nyhedsbreve med de vigtigste branchenyheder" de="Tägliche Newsletter mit den wichtigsten Updates" en="Daily newsletters with the most important industry news" no="Daglige nyhetsbrev med de viktigste bransjenyhetene" sv="Dagliga nyhetsbrev med de viktigaste branschnyheterna" />}
      </ListItem>

      <ListItem data-sentry-element="ListItem" data-sentry-source-file="ConsumerPerks.component.tsx">
        {newsTerm === "field" ? <Translation da="Ekstra nyhedsbreve når de største nyheder breaker" de="Zusätzliche Newsletter, wenn die größten Nachrichten brechen" en="Extra newsletters when the biggest news breaks" no="Ekstra nyhetsbrev når de største nyhetene bryter" sv="Extra nyhetsbrev när de största nyheterna bryter" /> : <Translation da="Topnyhedsbreve når de største branchenyheder breaker" de="Eilmeldungen per E-Mail, damit Sie immer auf dem Laufenden sind" en="Top newsletters when the biggest industry news breaks" no="Toppnyhetsbrev når de største bransjenyhetene bryter" sv="Toppnyhetsbrev när de största branschnyheterna bryter" />}
      </ListItem>

      {appStore !== null && googlePlay !== null ? <ListItem>
          <Translation da="Login til vores app - branchenyheder med på farten" de="Anmeldung zu unserer App - Branchennachrichten unterwegs" en="Login to our app - industry news on the go" no="Logg inn på appen vår - bransjenyheter på farten" sv="Logga in på vår app - branschnyheter på språng" />
        </ListItem> : null}
    </List>;
}