"use client";

import { addTrialToUser } from "@/app/(sites)/_containers/_actions/add-trial-to-user";
import { fetchUser } from "@/app/_api/services/fetch-user";
import type { UserModel } from "@/services/api/user/models/user.model";
import type { Fetchable } from "@/services/utilities/fetchable";
import { failed, hasFailed, succeeded } from "@/services/utilities/fetchable";

export function useAddTrial() {
  return {
    addTrialToCurrentProfile,
  };
}

async function addTrialToCurrentProfile(
  articleId: null | string,
): Promise<Fetchable> {
  const { user } = await fetchUser();

  const isUserNameValid = userHasFirstAndLastName(user) === true;

  if (!isUserNameValid) {
    return failed("ADD_TRIAL_INVALID_NAME");
  }

  const result = await addTrialToUser({
    articleId,
    user,
  });

  return hasFailed(result)
    ? failed("Could not add trial to user")
    : succeeded();
}

function userHasFirstAndLastName(user: UserModel.User | null): boolean {
  if (!user) {
    return false;
  }
  return user.firstName.trim() !== "" && user.lastName.trim() !== "";
}
