"use client";

import { RegularLinkButton } from "@/components/Button/RegularLinkButton/RegularLinkButton.component";
import { Card } from "@/components/Card/Card.component";
import { CardHeading } from "@/components/Card/_components/CardHeading/CardHeading.component";
import { PerksList } from "@/components/PerksList/PerksList.component";
import { Translation } from "@/components/Translation/Translation.component";
import { type Fetchable, initial, isPending } from "@/services/utilities/fetchable";
type ConsumerCardProps = {
  readonly url: string;
  readonly state?: Fetchable;
  readonly variant?: "outline" | "primary";
  readonly position?: string;
};
export function ConsumerCard({
  url,
  state = initial(),
  variant = "primary",
  position
}: ConsumerCardProps) {
  return <Card elevated={true} data-sentry-element="Card" data-sentry-component="ConsumerCard" data-sentry-source-file="ConsumerCard.components.tsx">
      <CardHeading data-sentry-element="CardHeading" data-sentry-source-file="ConsumerCard.components.tsx">
        <Translation da="Personligt abonnement" de="Persönliches Abonnement" en="Personal subscription" no="Personlig abonnement" sv="Personlig prenumeration" data-sentry-element="Translation" data-sentry-source-file="ConsumerCard.components.tsx" />
      </CardHeading>

      <p className="text-left text-sm md:text-base">
        <Translation da="Med et personligt abonnement får du:" de="Mit einem persönlichen Abonnement erhalten Sie:" en="With a personal subscription you get:" no="Med et personlig abonnement får du:" sv="Med en personlig prenumeration får du:" data-sentry-element="Translation" data-sentry-source-file="ConsumerCard.components.tsx" />
      </p>

      <PerksList variant="consumer" data-sentry-element="PerksList" data-sentry-source-file="ConsumerCard.components.tsx" />

      <RegularLinkButton disabled={isPending(state)} href={url} position={position} variant={variant} data-sentry-element="RegularLinkButton" data-sentry-source-file="ConsumerCard.components.tsx">
        <Translation da="Køb" de="Kaufen" en="Buy" no="Kjøp" sv="Köp" data-sentry-element="Translation" data-sentry-source-file="ConsumerCard.components.tsx" />
      </RegularLinkButton>
    </Card>;
}