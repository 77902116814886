import { List } from "@/components/List/List.component";
import { ListItem } from "@/components/List/ListItem.component";
import { Translation } from "@/components/Translation/Translation.component";
import clsx from "clsx";
type CorporatePerksProps = {
  readonly className?: string;
};
export function CorporatePerks({
  className
}: CorporatePerksProps) {
  return <List className={clsx(className, "gap-1")} type="checkmark" data-sentry-element="List" data-sentry-component="CorporatePerks" data-sentry-source-file="CorporatePerks.component.tsx">
      <ListItem data-sentry-element="ListItem" data-sentry-source-file="CorporatePerks.component.tsx">
        <Translation da="Alle fordele fra et personligt abonnement" de="Vorteile Ihrer persönlichen Lizenz" en="All the benefits of a personal subscription" no="Alle fordelene fra våre personlige abonnementer" sv="Alla fördelar med en personlig prenumeration" data-sentry-element="Translation" data-sentry-source-file="CorporatePerks.component.tsx" />
      </ListItem>

      <ListItem data-sentry-element="ListItem" data-sentry-source-file="CorporatePerks.component.tsx">
        <Translation da="Løsning tilpasset jeres behov - adgang via IP eller mail&shy;-domæne" de="Lösung, die Ihren Anforderungen entspricht - Zugriff über IP oder E-Mail-Domäne" en="Solution tailored to your needs - access via IP or email domain" no="Løsning tilpasset deres behov - tilgang via IP eller e-postdomene" sv="Lösning anpassad efter era behov - åtkomst via IP eller e-postdomän" data-sentry-element="Translation" data-sentry-source-file="CorporatePerks.component.tsx" />
      </ListItem>

      <ListItem data-sentry-element="ListItem" data-sentry-source-file="CorporatePerks.component.tsx">
        <Translation da="2-1000+ brugere" de="2-1000+ Benutzer" en="2-1000+ users" no="2-1000+ brukere" sv="2-1000+ användare" data-sentry-element="Translation" data-sentry-source-file="CorporatePerks.component.tsx" />
      </ListItem>

      <ListItem data-sentry-element="ListItem" data-sentry-source-file="CorporatePerks.component.tsx">
        <Translation da="Attraktiv pris per bruger" de="Attraktiver Preis pro Benutzer" en="Attractive price per user" no="Attraktiv pris per bruker" sv="Attraktivt pris per användare" data-sentry-element="Translation" data-sentry-source-file="CorporatePerks.component.tsx" />
      </ListItem>

      <ListItem data-sentry-element="ListItem" data-sentry-source-file="CorporatePerks.component.tsx">
        <Translation da="Samlet årlig eller kvartalsvis fakturering" de="Jährliche oder vierteljährliche Abrechnung" en="Annual or quarterly billing" no="Årlig eller kvartalsvis fakturering" sv="Årlig eller kvartalsvis fakturering" data-sentry-element="Translation" data-sentry-source-file="CorporatePerks.component.tsx" />
      </ListItem>
    </List>;
}