"use client";

import { ConsumerPerks } from "./_components/ConsumerPerks.component";
import { CorporatePerks } from "./_components/CorporatePerks.component";
type PerksListProps = {
  readonly className?: string;
  readonly variant: "consumer" | "corporate";
};
export function PerksList({
  className,
  variant
}: PerksListProps) {
  if (variant === "corporate") return <CorporatePerks className={className} />;
  return <ConsumerPerks className={className} data-sentry-element="ConsumerPerks" data-sentry-component="PerksList" data-sentry-source-file="PerksList.component.tsx" />;
}