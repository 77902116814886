"use client";

import { ErrorMessage } from "@/components/ErrorMessage/ErrorMessage.component";
import { Spinner } from "@/components/Spinner/Spinner.component";
import { Translation } from "@/components/Translation/Translation.component";
import { useSite } from "@/contexts/site/site.context";
import { hasFailed, initial, isPending } from "@/services/utilities/fetchable";
import type { ErrorMessageStatus, Fetchable } from "@/services/utilities/fetchable";
import { inlineJsx } from "@/ts/locale/inline-jsx";
import clsx from "clsx";
import type { ReactNode } from "react";
type AddTrialButtonProps = {
  readonly articleId: null | string;
  readonly state?: Fetchable;
  readonly numberOfTrialDays: number;
  readonly addTrialComponent?: ReactNode;
  readonly className?: string;
};
export function AddTrialLink({
  addTrialComponent = null,
  className,
  numberOfTrialDays,
  state = initial()
}: AddTrialButtonProps) {
  const {
    name: siteName
  } = useSite();
  return <div className={clsx(className, "m-auto inline-flex flex-col text-center text-sm md:text-base")} data-sentry-component="AddTrialLink" data-sentry-source-file="AddTrialLink.component.tsx">
      <p className="flex-auto">
        <Translation da={inlineJsx`Prøv ${siteName} i ${numberOfTrialDays} dage - og få adgang til alt indhold.`} de={inlineJsx`Testen Sie ${siteName} ${numberOfTrialDays} Tage lang - und erhalten Sie Zugang zu allen Inhalten.`} en={inlineJsx`Try ${siteName} for ${numberOfTrialDays} days - and get access to all content.`} no={inlineJsx`Prøv ${siteName} i ${numberOfTrialDays} dager - og få tilgang til alt innhold.`} sv={inlineJsx`Prova ${siteName} i ${numberOfTrialDays} dagar - och få tillgång till allt innehåll.`} data-sentry-element="Translation" data-sentry-source-file="AddTrialLink.component.tsx" />
      </p>

      {isPending(state) ? null : addTrialComponent}

      {isPending(state) ? <div className="flex justify-center">
          <Spinner className="h-3 w-3 rounded-full fill-primary text-whisper group-disabled:fill-silver" />
        </div> : null}

      <div className="mt-1">
        {hasFailed(state) ? <ErrorMessage status={state.errorMessage as ErrorMessageStatus} /> : null}
      </div>
    </div>;
}